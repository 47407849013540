"use strict";
exports.__esModule = true;
var Explorer_1 = require("../../components/Explorer");
var initSubmenus_1 = require("../../includes/initSubmenus");
var initSkipLink_1 = require("../../includes/initSkipLink");
document.addEventListener('DOMContentLoaded', function () {
    var explorerNode = document.querySelector('[data-explorer-menu]');
    var toggleNode = document.querySelector('[data-explorer-start-page]');
    if (explorerNode && toggleNode) {
        Explorer_1.initExplorer(explorerNode, toggleNode);
    }
    initSubmenus_1.initSubmenus();
    initSkipLink_1.initSkipLink();
});
